define("web-chat/components/relative-day", ["exports", "web-core/components/relative-day"], function (_exports, _relativeDay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _relativeDay.default;
    }
  });
});