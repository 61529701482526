define("web-chat/templates/components/reply-room-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hD4fqv+U",
    "block": "[[[10,0],[15,0,[29,[\"reply-room-event \",[52,[30,0,[\"downloadUrl\"]],\"reply-room-event--download\"]]]],[12],[10,0],[14,0,\"reply-room-event__wrapper\"],[12],[10,0],[14,0,\"reply-room-event__meta\"],[12],[41,[28,[37,1],[[30,1,[\"sender\"]],[30,0,[\"account\",\"user\"]]],null],[[[10,0],[14,0,\"reply-room-event__sender\"],[12],[1,[28,[35,2],[\"reply.me\"],null]],[13]],[]],[[[10,0],[14,0,\"reply-room-event__sender\"],[15,\"title\",[30,1,[\"sender\",\"displayName\"]]],[12],[41,[30,1,[\"sender\",\"displayName\"]],[[[1,[30,1,[\"sender\",\"displayName\"]]]],[]],[[[1,[28,[35,2],[\"common.customer\"],null]]],[]]],[13]],[]]],[13],[1,\" \"],[10,0],[14,0,\"reply-room-event__content\"],[12],[41,[30,0,[\"isRedacted\"]],[[[10,0],[14,0,\"reply-room-event__body event-type__body\"],[12],[10,0],[14,0,\"room-event__redacted\"],[12],[1,[28,[35,2],[\"room_event.redacted\"],null]],[13],[13]],[]],[[[10,0],[15,0,[29,[\"reply-room-event__body event-type__body \",[52,[28,[37,1],[[30,1,[\"content\",\"format\"]],\"text/html\"],null],\"event-type__body--html\"]]]],[12],[10,1],[12],[1,[30,0,[\"body\"]]],[13],[1,\" \"],[41,[30,0,[\"isEdit\"]],[[[10,1],[14,0,\"room-event__edited\"],[15,\"title\",[28,[37,2],[\"edit_event.edit\"],[[\"date\"],[[28,[37,3],[[30,1,[\"created\"]]],[[\"format\"],[\"yymmddhhmm\"]]]]]]],[12],[1,\" (\"],[1,[28,[35,2],[\"common.edited\"],null]],[1,\") \"],[13]],[]],null],[13]],[]]],[13],[1,\" \"],[41,[30,0,[\"downloadUrl\"]],[[[10,3],[14,0,\"event-type--s-message-media__download reply-room-event__download\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,\"title\",[28,[37,2],[\"actions.download\"],null]],[15,6,[30,0,[\"downloadUrl\"]]],[12],[1,[28,[35,4],[\"icon-download\"],null]],[13]],[]],null],[13],[13]],[\"@event\"],false,[\"if\",\"eq\",\"t\",\"format-date\",\"svg-jar\"]]",
    "moduleName": "web-chat/templates/components/reply-room-event.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});