define("web-chat/initializers/polyfill", ["exports", "url-search-params-polyfill"], function (_exports, _urlSearchParamsPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // polyfill element matches for IE11
  if (!Element.prototype.matches) {
    Element.prototype.matches = // @ts-ignore
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias,unicorn/no-this-assignment
      var el = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);

      return null;
    };
  }

  if (!Element.prototype.remove) {
    (function (arr) {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
          return;
        }

        Object.defineProperty(item, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            // eslint-disable-next-line unicorn/prefer-dom-node-remove
            this.parentNode.removeChild(this);
          }
        });
      });
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  function initialize() {// doesn't have to do anything on function call as it's only polyfilling once
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});