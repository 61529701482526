define("web-chat/router", ["exports", "web-chat/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('root', {
      path: '/:livechat_id'
    }, function () {
      this.route('room', function () {});
      this.route('offline');
      this.route('blocked');
    });
    this.route('boot-failed');
  });
  var _default = Router;
  _exports.default = _default;
});