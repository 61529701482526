define("web-chat/templates/root/blocked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vghtLc1+",
    "block": "[[[10,0],[14,0,\"room room--hidden-input\"],[12],[10,0],[14,0,\"events-log room__events\"],[12],[10,0],[14,0,\"events-log__content\"],[12],[10,0],[14,0,\"room-empty\"],[12],[10,0],[14,0,\"room-empty__icon\"],[12],[1,[28,[35,0],[\"icon-cancel\"],null]],[13],[1,\" \"],[10,0],[14,0,\"room-empty__description\"],[12],[1,[28,[35,1],[\"room.blocked\"],null]],[13],[13],[13],[13],[13]],[],false,[\"svg-jar\",\"t\"]]",
    "moduleName": "web-chat/templates/root/blocked.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});