define("web-chat/templates/components/room-status-outside-business-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B3sn7WkG",
    "block": "[[[10,0],[14,0,\"room-status room-status--input\"],[12],[10,0],[14,0,\"room-status__message\"],[12],[1,[30,0,[\"offline\",\"labels\",\"outside_business_hours\"]]],[13],[13]],[],false,[]]",
    "moduleName": "web-chat/templates/components/room-status-outside-business-hours.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});