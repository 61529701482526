define("web-chat/templates/root/offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xRRH3pwv",
    "block": "[[[10,0],[14,0,\"room room--hidden-input\"],[12],[10,0],[14,0,\"events-log room__events\"],[12],[10,0],[14,0,\"events-log__content\"],[12],[10,0],[14,0,\"room-empty\"],[12],[41,[28,[37,1],[[30,0,[\"pairing\",\"supportRejectReason\"]],\"OUTSIDE_BUSINESS_HOURS\"],null],[[[10,0],[14,0,\"room-empty__icon\"],[12],[1,[28,[35,2],[\"icon-event-busy\"],null]],[13],[1,\" \"],[10,0],[14,0,\"room-empty__description\"],[12],[1,[30,0,[\"model\",\"labels\",\"outside_business_hours\"]]],[13]],[]],null],[13],[13],[13],[13]],[],false,[\"if\",\"eq\",\"svg-jar\"]]",
    "moduleName": "web-chat/templates/root/offline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});