define("web-chat/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "ember-simple-auth/session-stores/ephemeral", "ember-simple-auth/session-stores/local-storage", "web-core/utils/supports"], function (_exports, _cookie, _ephemeral, _localStorage, _supports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BaseStore = _ephemeral.default;

  if ((0, _supports.default)('cookies')) {
    BaseStore = _cookie.default;
  } else if ((0, _supports.default)('localStorage')) {
    BaseStore = _localStorage.default;
  }

  try {
    // if we have a flowId in the preconditions, we need to use the ephemeral store
    var search = new URLSearchParams(window.location.search);

    if (search.get('preconditions')) {
      var preconditions = JSON.parse(search.get('preconditions'));

      if (preconditions.flowId) {
        BaseStore = _ephemeral.default;
      }
    }

    if (search.get('options')) {
      var options = JSON.parse(search.get('options'));

      if (options.credentialless) {
        // if we have credentialless option in the search params, we need to use the ephemeral store
        BaseStore = _ephemeral.default;
      }
    }
  } catch (_unused) {}

  var _default = BaseStore.extend({
    sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
    cookieName: 'smoope-web-chat',
    key: 'smoope-web-chat',
    instanceConfig: Ember.inject.service(),
    // If credentialless changes to true, we need to clear the store
    // eslint-disable-next-line ember/no-observers
    credentiallessChanged: Ember.observer('instanceConfig.credentialless', function () {
      if (this.get('instanceConfig.credentialless')) {
        this.clear(true);
      }
    }),
    persist: function persist(data) {
      this._data = JSON.stringify(data || {});

      if (!this.get('instanceConfig.credentialless')) {
        return this._super(data);
      }

      return Ember.RSVP.resolve();
    },
    restore: function restore() {
      if (!this.get('instanceConfig.credentialless')) {
        return this._super();
      }

      var data = {};

      try {
        data = JSON.parse(this._data);
      } catch (_unused2) {}

      return Ember.RSVP.resolve(data);
    },
    clear: function clear() {
      var clearBaseStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (clearBaseStore) {
        return this._super();
      }

      if (this.get('instanceConfig.credentialless')) {
        delete this._data;
        this._data = '{}';
      }

      return this._super();
    }
  });

  _exports.default = _default;
});